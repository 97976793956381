var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid h-full" }, [
    _vm.noCashoutAccount
      ? _c(
          "div",
          { staticClass: "my-10" },
          [
            _c("img", {
              staticClass: "mx-auto h-36",
              attrs: {
                src: "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1631996266/checkout/50_1_o6ver8.png",
                alt: "Order Invalid",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center text-xl font-semibold my-10" },
              [_vm._v(" You need to provide a bank account for withdrawal ")]
            ),
            _c("Button", {
              staticClass: "mt-12 mx-auto",
              attrs: {
                text: "Go to settings",
                shadow: true,
                p: "p-3",
                width: "w-full",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/settings")
                },
              },
            }),
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            [
              _c("CurrencyInput", {
                staticClass: "mt-6",
                attrs: {
                  width: "w-full",
                  placeholder: "Withdrawal Amount",
                  errorText: "Withdrawal amount is required",
                  validation: _vm.rules.amount,
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.amount = $event
                  },
                },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
              _c("Input", {
                staticClass: "mt-10",
                attrs: { width: "w-full", placeholder: "Notes" },
                model: {
                  value: _vm.note,
                  callback: function ($$v) {
                    _vm.note = $$v
                  },
                  expression: "note",
                },
              }),
              _c("div", { staticClass: "mt-10 text-dark" }, [
                _c("p", { staticClass: "font-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.cashoutAccount.number) +
                      " - " +
                      _vm._s(_vm.bankName) +
                      " "
                  ),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.cashoutAccount.name))]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "items-end w-full mt-10" }, [
            _c(
              "div",
              { staticClass: "place-self-end" },
              [
                _c("div", { staticClass: "spam-notice mb-6 rounded-lg" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                          fill: "#0EA2F5",
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "text-sm text-blue" }, [
                    _vm._v(
                      " Note: You will be charged a " +
                        _vm._s(_vm._f("formatMoney")(100)) +
                        " fee for this transaction "
                    ),
                  ]),
                ]),
                _c("Button", {
                  staticClass: "mt-6",
                  attrs: {
                    text: "Withdraw",
                    shadow: true,
                    width: "w-full",
                    p: "p-3",
                    loading: _vm.loading,
                    disabled: _vm.disableButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.proceed()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }