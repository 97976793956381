<template>
  <div class="grid h-full">
    <div class="my-10" v-if="noCashoutAccount">
      <img
        src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1631996266/checkout/50_1_o6ver8.png"
        alt="Order Invalid"
        class="mx-auto h-36"
      />
      <p class="text-center text-xl font-semibold my-10">
        You need to provide a bank account for withdrawal
      </p>
      <Button
        text="Go to settings"
        :shadow="true"
        p="p-3"
        class="mt-12 mx-auto"
        width="w-full"
        @click="$router.push('/settings')"
      />
    </div>
    <div v-else>
      <div>
        <CurrencyInput
          width="w-full"
          placeholder="Withdrawal Amount"
          class="mt-6"
          v-model="amount"
          errorText="Withdrawal amount is required"
          :validation="rules.amount"
          @valid="valid.amount = $event"
        />
        <Input
          width="w-full"
          placeholder="Notes"
          class="mt-10"
          v-model="note"
        />
        <div class="mt-10 text-dark">
          <p class="font-semibold">
            {{ cashoutAccount.number }} - {{ bankName }}
          </p>
          <p>{{ cashoutAccount.name }}</p>
        </div>
      </div>
      <div class="items-end w-full mt-10">
        <div class="place-self-end">
          <div class="spam-notice mb-6 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                fill="#0EA2F5"
              />
            </svg>
            <p class="text-sm text-blue">
              Note: You will be charged a {{ 100 | formatMoney }} fee for this
              transaction
            </p>
          </div>
          <Button
            text="Withdraw"
            :shadow="true"
            width="w-full"
            p="p-3"
            class="mt-6"
            @click="proceed()"
            :loading="loading"
            :disabled="disableButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { CurrencyInput, Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      CurrencyInput,
      Input,
      Button,
    },
    data() {
      return {
        amount: 0,
        note: "",
        loading: false,
        valid: {
          amount: false,
        },
      };
    },
    props: {
      cashoutAccount: {
        type: Object,
        default: () => {},
        required: true,
      },
      noCashoutAccount: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    computed: {
      rules() {
        return {
          amount: this.amount !== 0,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
      bankName() {
        return this.cashoutAccount?.bank?.prettyName;
      },
    },
    methods: {
      ...mapActions("dashboard", ["withdrawFromWallet", "getUserWallet"]),
      ...mapActions("notification", ["showAlert"]),
      proceed() {
        this.loading = true;
        this.withdrawFromWallet({
          amount: this.amount,
        })
          .then((res) => {
            this.loading = false;
            this.getUserWallet();
            if (res.data.data.status === "SUCCESS") {
              this.showAlert({
                display: true,
                type: "success",
                description: `Your withdrawal was successful`,
              });
            } else if (res.data.data.status === "FAILED") {
              this.showAlert({
                description:
                  "Your attempt to withdraw from your wallet failed, please contact support.",
                display: true,
                type: "error",
              });
            } else {
              this.showAlert({
                description:
                  "Your withdrawal request is still processing. Please contact support if you have not been funded after a while.",
                display: true,
                type: "success",
              });
            }
            this.$emit("completed");
          })
          .catch((err) => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style></style>
